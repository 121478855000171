import { getAuth, signInWithEmailAndPassword } from '@firebase/auth';
import React, { useState } from 'react';
import { useFirebaseApp } from 'reactfire';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';

function SignInForm() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const app = useFirebaseApp();
    const auth = getAuth(app);

    function submit(e: React.FormEvent) {
        signInWithEmailAndPassword(auth, email, password)
        .catch((error) => {
            const errorMessage = error.message;
            console.error(errorMessage);
        });
        e.preventDefault();
    }

    return (
        <Form onSubmit={e => submit(e)}>
            <Form.Group className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="example@example.com" value={email} onChange={e => setEmail(e.target.value)} required />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" autoComplete="current-password" value={password} onChange={e => setPassword(e.target.value)} required/>
            </Form.Group>
            <Button variant="primary" type="submit">Submit</Button>
        </Form>
    );
}

export default SignInForm;
