import { getAuth, signOut } from '@firebase/auth';
import { collection, orderBy, query } from '@firebase/firestore';
import { useFirebaseApp, useFirestore, useFirestoreCollectionData } from 'reactfire';
import Table from 'react-bootstrap/Table';
import { Button } from 'react-bootstrap';

function EquipmentTable() {
    const app = useFirebaseApp();
    const auth = getAuth(app);

    const equipmentCollection = collection(useFirestore(), 'equipment');
    const equipmentQuery = query(equipmentCollection, orderBy('memberID', 'asc'));
    const { status, data: equipmentArray } = useFirestoreCollectionData(equipmentQuery, {
        idField: 'id',
    });

    function handleSignOut() {
        signOut(auth);
    }

    if (status === 'loading') {
        return (
            <p>Loading...</p>
        )
    }

    return (
        <div className="EquipmentTable">
            <Table striped bordered hover className="mb-3">
                <thead>
                    <tr>
                        <th colSpan={3} >Equipment Table</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="col">Member Number</th>
                        <th scope="col">DCC Address</th>
                        <th scope="col">Description</th>
                    </tr>
                    {equipmentArray.map((equipment) => (
                        <tr key={equipment.id}>
                            <td>{ equipment.memberID }</td>
                            <td>{ equipment.dccAddress }</td>
                            <td>{ equipment.description }</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Button variant="danger" onClick={handleSignOut}>Sign Out</Button>
        </div>
    );
}

export default EquipmentTable;
