import { getAuth } from "@firebase/auth";
import { getFirestore } from "@firebase/firestore";
import { AuthProvider, FirebaseAppProvider, FirestoreProvider, useFirebaseApp } from "reactfire";
import App from "../App/App";

function FirebaseApp() {
    const firebaseConfig = {
        apiKey: "AIzaSyCyzwZbEso4wszaZedIXewdoVRt0CjLYEw",
        authDomain: "cgmrc-members.firebaseapp.com",
        databaseURL: "https://cgmrc-members-default-rtdb.firebaseio.com",
        projectId: "cgmrc-members",
        storageBucket: "cgmrc-members.appspot.com",
        messagingSenderId: "466463095762",
        appId: "1:466463095762:web:a6498dbd0013ec114ae60b"
    };

    return (
        <FirebaseAppProvider firebaseConfig={firebaseConfig}>
            <FirebaseComponent />
        </FirebaseAppProvider>
    );
}

function FirebaseComponent() {
    const app = useFirebaseApp();
    const auth = getAuth(app);
    const firestore = getFirestore(app);

    return (
        <AuthProvider sdk={auth}>
            <FirestoreProvider sdk={firestore}>
                <App />
            </FirestoreProvider>
        </AuthProvider>
    );
}

export default FirebaseApp;
