import { Container } from 'react-bootstrap';
import { useSigninCheck } from 'reactfire';
import EquipmentTable from '../EquipmentTable/EquipmentTable';
import SignInForm from '../SignInForm/SignInForm';
import Header from '../Header/Header';

function App() {
    const { status, data: signInCheckResult } = useSigninCheck();

    if (status === 'loading') {
        return <p>Loading...</p>;
    }

    return (
        <>
            <Header />
            <Container>
                {signInCheckResult.signedIn === true
                ? <EquipmentTable />
                : <SignInForm />
                }
            </Container>
        </>
    );
}

export default App;
