import { Container, Navbar } from "react-bootstrap";

function Header() {
    return (
        <Navbar bg="light" className="mb-4">
            <Container>
                <Navbar.Brand>CGMRC Member Site</Navbar.Brand>
            </Container>
        </Navbar>
    );
}

export default Header;
